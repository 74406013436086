import Icon from 'svgs/redesign/right-arrow-decal.svg';

import * as styles from './index.module.scss';

const rightArrowDecal = () => {
  return (
    <div className={styles['right-arrow-decal']}>
      <Icon />
    </div>
  );
};

export default rightArrowDecal;
